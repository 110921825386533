<template>
  <div class="wrap">
    <div class="pur-top-right-icon">
      <i
        class="iconfont icon-wuliujiedian_xiadan"
        :class="'i-green'"
      />
      <span
        :class="[currentIndex > 1 ? 'green' : 'gray']"
      ></span>
      <i
        v-if="!CANCEL && isTc == true"
        class="iconfont icon-wuliujiedian_pingshen1"
        :class="[currentIndex >= 2 ? 'i-green' : 'i-gray']"
      />
      <span
        :class="[currentIndex > 2 ? 'green' : 'gray']"
        v-if="!CANCEL && isTc == true"
      ></span>
      <i
        v-if="!CANCEL && isTc == true"
        class="iconfont icon-wuliujiedian_wuliujiedan1"
        :class="[currentIndex >= 3 ? 'i-green' : 'i-gray']"
      />
      <span
        :class="[currentIndex > 3 ? 'green' : 'gray']"
        v-if="!CANCEL && isTc == true"
      ></span>
      <i
        v-if="!CANCEL && isTc == true"
        class="iconfont icon-wuliujiedian_tiaodupeiche1"
        :class="[currentIndex >= 4 ? 'i-green' : 'i-gray']"
      />
      <span
        :class="[currentIndex > 4 ? 'green' : 'gray']"
        v-if="!CANCEL && isTc == true"
      ></span>
      <i
        v-if="!CANCEL && isTc == true"
        class="iconfont icon-wuliujiedian_fayunchuku1"
        :class="[currentIndex >= 5 ? 'i-green' : 'i-gray']"
      />
      <span
        :class="[currentIndex > 5 ? 'green' : 'gray']"
        v-if="!CANCEL && isTc == true"
      ></span>
      <i
        v-if="!CANCEL"
        class="iconfont icon-wuliujiedian_kehuqianshou1"
        :class="[currentIndex >= 6 ? 'i-green' : 'i-gray']"
      />
      <i
        v-if="CANCEL"
        class="iconfont icon-wuliujiedian_quxiao i-gray"
      />
    </div>
    <div class="pur-top-right-txt">
      <div>
        <p style="padding-right:4px">下单</p>
        <!--        <p v-if="currentIndex >= 1">{{ this.statusObj.UNCHKED }}</p>-->
        <p v-if="currentIndex >= 1">{{  orderdetail.documentDate && formatDate(orderdetail.documentDate) }}</p>
      </div>
      <div v-if="!CANCEL && isTc == true">
        <p :class="[currentIndex >= 2 ? '' : 'gray-p']">{{ secondStepName }}</p>
      
      <!--        <p v-if="currentIndex >= 2">{{ this.statusObj.REVIEWCOMPLETED }}</p>-->
        <p v-if="currentIndex >= 2">{{ orderdetail.acknowledgementDate &&formatDate(orderdetail.acknowledgementDate) }}</p>
      </div>
      <div v-if="!CANCEL && isTc == true">
        <p :class="[currentIndex >= 3 ? '' : 'gray-p']">物流接单</p>
        <p v-if="currentIndex >= 3">{{ orderdetail.omsReceiveDate &&formatDate(orderdetail.omsReceiveDate) }}</p>
      </div>
      <div v-if="!CANCEL && isTc == true">
        <a-popover placement="bottom" v-if="orderdetail.excuteOrg">
          <template slot="content">
            <p>{{orgConfigs.orgName}}</p>
            <template v-if="orgConfigs.orgConfigs && orgConfigs.orgConfigs.length > 0">
              <template v-for="(item, itemIndex) in orgConfigs.orgConfigs">
                <p>{{item.title}}</p>
                <template v-for="(child, childIndex) in item.contents">
                  <p>{{child}}</p>
                </template>
              </template>
            </template>
            <p v-else>物流17:00前接收的订单，将当天发货。17:00后接收的订单，将在次日17:00前发出。</p>
          </template>
          <template slot="title">
            <span>物流发运规则</span>
          </template>
          <p :class="[currentIndex >= 4 ? '' : 'gray-p']">调度配车<i style='color:#00AAA6;font-size: 12px;margin-left: 4px;' class="iconfont icon-wentitubiao"></i></p>
        </a-popover>
        <p :class="[currentIndex >= 4 ? '' : 'gray-p']" v-else>调度配车</p>
        <!--        <p v-if="currentIndex >= 3">{{ this.statusObj.WAITDELIVER }}</p>-->
        <p v-if="currentIndex >= 4">{{ orderdetail.outDate &&formatDate(orderdetail.outDate) }}</p>
      </div>
      <div v-if="!CANCEL && isTc == true">
        <p :class="[currentIndex >= 5 ? '' : 'gray-p']">发运出库</p>
        <p v-if="currentIndex >= 5">{{ orderdetail.omsOutboundDate &&formatDate(orderdetail.omsOutboundDate) }}</p>
        <!--        <p v-if="currentIndex >= 4">{{ this.statusObj.PARTCHECKED }}</p>-->
      </div>
      <div v-if="!CANCEL">
        <p :class="[currentIndex >= 6 ? '' : 'gray-p']">客户签收</p>
        <!--        <p v-if="currentIndex >= 5">{{ this.statusObj.CUSTSIGIN }}</p>-->
        <p v-if="currentIndex >= 6">{{ orderdetail.omsFinishedDate &&formatDate(orderdetail.omsFinishedDate) }}</p>
      </div>
      <div v-if="CANCEL">
        <p>取消</p>
        <p>{{ this.statusObj.CANCEL }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import dmsService from "@/utils/dmsrequest";
moment.locale("zh-cn");
export default {
  name: "Step",
  props: {
    status: {
      type: String
  },
    orderdetail:{
      type:Object
    }
  },
  computed: {
    currentIndex: function() {
      let step = 1;
      if(this.omsOrderStatusItem.length > 0){
        if(this.omsOrderStatusItem[this.omsOrderStatusItem.length - 1].orderStatus == "ORDER_ONROAD"){
          step = 5;
        }else if(this.omsOrderStatusItem[this.omsOrderStatusItem.length - 1].orderStatus == "CONFIRM_SHIPMENT"){
          step = 4;
        }else if(this.omsOrderStatusItem[this.omsOrderStatusItem.length - 1].orderStatus == "CREATE_SUCCESS"){
          step = 3;
        }
        return step;
      }else if (this.status) {
        const status = this.status;
        if (status == "submitted") {
          step = 2;
        }
        if (status == "confirmed") {
          step = 2;
        }
        if (status == "partially-confirm") {
          step = 2;
        }
        if (status == "examined") {
          step = 2;
        }
        if (status == "refused") {
          step = 2;
        }
        if (status == "disagreed") {
          step = 2;
        }
        
        if (status == "receiveOrder") {
          step = 3;
        }
        if (status == "shipped") {
          step = 6;
        }
        if (status == "part_shipped") {
          step = 6;
        }
        if (status == "unoperate") {
          step = 4;
        }
        if(status =="intransit") {
          step = 5
        }
        if (status == "unoperate") {
          step = 5;
        }
        if (status == "finished") {
          step = 6;
        }
        if (status == "operated" || status=='shipped') {
          step = 6;
        }

        return step;
      } else {
        return step;
      }
    }
  },
  watch: {
    "orderdetail":{
      handler:function(){
         this.statusObj = {};
          this.statusObj = this.orderdetail
          this.CANCEL = this.orderdetail.status == 'freeze' ? true : false
          this.omsOrderStatusItem = this.orderdetail.omsOrderStatusItem && this.orderdetail.omsOrderStatusItem.length > 0 ? this.orderdetail.omsOrderStatusItem : []
          if(this.orderdetail.salesOrderItem && this.orderdetail.salesOrderItem.length > 0 && this.orderdetail.salesOrderItem[0].gicOutWarehouseType == "70"){
            this.isTc = false
          }
          if(this.orderdetail.excuteOrg){
            dmsService({
              url: 'findOmsOrgConfigByOrgName',
              data: {
                orgName: this.orderdetail.excuteOrg
              }
            }).then(res => {
              let result;
              if(typeof res.data == "string"){
                result = JSON.parse(res.data)
              }else{
                result = res.data;
              }
              this.orgConfigs = result.data;
            })
          }
      },
      immediate: true
    },
    status: {
      handle:function(){
        const status = this.status;
        if (status == "confirmed") {
          this.secondStepName = "评审完成";
        }
        if (status == "partially-confirm") {
          this.secondStepName = "部分确认";
        }
        if (status == "examined") {
          this.secondStepName = "已审核";
        }
        if (status == "refused") {
          this.secondStepName = "已拒绝";
        }
        if (status == "disagreed") {
          this.secondStepName = "已拒绝";
        }
      },
      immediate: true
    },
  },
  data() {
    return {
      secondStepName: "订单评审",
      statusObj: {},
      CANCEL: false,
      orgConfigs: {},
      isTc: true,
      omsOrderStatusItem: []
    };
  },
  mounted(){
   
  
  },
  methods:{
    formatDate(date){
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    }
  }
};
</script>

<style scoped lang="less">
.wrap {
  width: 930px;
  height: 139px;
  background: #fff;

  .pur-top-right-icon {
    text-align: left;
    padding-left: 68px;

    .iconfont {
      border-radius: 50%;
      width: 42px;
      height: 42px;
      line-height: 42px;
      font-size: 24px;
      position: relative;
      top: 8px;
      text-align: center;
      color: #fff;
    }

    .i-green {
      background: #20C6BA;
    }

    .i-gray {
      background: #CCCCCC;
    }

    img {
      width: 36px;
      height: 36px;
    }

    span {
      width: 94px;
      margin: 18px 8px 0;
    }

    .green {
      border-bottom: 1px dashed #00aaa6;
    }

    .gray {
      border-bottom: 1px dashed #cccccc;
    }
  }

  .pur-top-right-txt {
    margin: 16px 0 0 28px;
    :last-child {
      margin-right: 0px;
    }

    div {
      float: left;
      width: 120px;
      margin: 0 32px 0 0;

      p {
        font-size: 12px;
        font-weight: 500;
        color: #262626;
        line-height: 12px;
        margin-bottom: 7px;
      }
    }
    .gray-p{
      color: #aaa;
    }
  }
}
</style>
