<template>
  <div class="wrap">
    <div class="pur-top-right-icon">
      <i
        class="iconfont icon-wuliujiedian_xiadan i-green"
      />
      <span class="green" ></span>
      <i
        class="iconfont icon-wuliujiedian_pingshen1 i-gray"
      />
    </div>
    <div class="pur-top-right-txt">
      <div>
        <p>下单</p>
        <p>{{  documentDate && formatDate(documentDate) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
moment.locale("zh-cn");
export default {
  name: "Step",
  props: {
    documentDate:{
      type: String
    }
  },
  methods:{
    formatDate(date){
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    }
  }
};
</script>

<style scoped lang="less">
  .wrap {
    width: 930px;
    height: 139px;
    background: #fff;
  
    .pur-top-right-icon {
      text-align: left;
      padding-left: 68px;
  
      .iconfont {
        border-radius: 50%;
        width: 42px;
        height: 42px;
        line-height: 42px;
        font-size: 24px;
        position: relative;
        top: 8px;
        text-align: center;
        color: #fff;
      }
  
      .i-green {
        background: #20C6BA;
      }
  
      .i-gray {
        background: #CCCCCC;
      }
  
      img {
        width: 36px;
        height: 36px;
      }
  
      span {
        width: 94px;
        margin: 18px 8px 0;
      }
  
      .green {
        border-bottom: 1px dashed #00aaa6;
      }
  
      .gray {
        border-bottom: 1px dashed #cccccc;
      }
    }
  
    .pur-top-right-txt {
      margin: 16px 0 0 28px;
      :last-child {
        margin-right: 0px;
      }
  
      div {
        float: left;
        width: 120px;
        margin: 0 32px 0 0;
  
        p {
          font-size: 12px;
          font-weight: 500;
          color: #262626;
          line-height: 12px;
          margin-bottom: 7px;
        }
      }
    }
  }
  </style>
